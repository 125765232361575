import React, { useEffect } from 'react'
import { logout } from '../services/authentication'
import { navigate } from 'gatsby'

const Logout = () => {
  useEffect(() => {
    logout()
    navigate('/')
  }, [])
}

export default Logout
